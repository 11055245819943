import boulder from 'assets/icons/client-logos/boulder-nv.png'
import paradise_valley from 'assets/icons/client-logos/paradise-valley-az-2.png'
import utah_county from 'assets/icons/client-logos/utah-county.jpg'
import utah_lake_commission from 'assets/icons/client-logos/utah-lake-commission.png'
import React from 'react'
import styled from 'styled-components'

const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const ImagesContainer = styled.div`
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const Image = styled.img`
  width: 200px;
  height: 200px;
  margin: 1em;
`

const Clients = () => {
  return (
    <RootContainer>
      <h2>Our Municipal Customers</h2>
      <ImagesContainer>
        <Image src={boulder} />
        <Image src={paradise_valley} />
        <Image src={utah_county} />
        <Image src={utah_lake_commission} />
      </ImagesContainer>
    </RootContainer>
  )
}

export default Clients
