import { Layout, SEO } from 'components/common'
import Clients from 'components/common/Clients'
import { Header } from 'components/theme'
import React from 'react'
import styled from 'styled-components'

const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TextWrapper = styled.div`
  width: 420px;
  display: flex;
  font-size: 20px;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 80%;
    font-size: 16px;
  }
`

const MunicipalTranscription = () => {
  return (
    <Layout>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO
        title='Municipal Transcription'
        description='Summarized meeting minutes for municipalities. We can handle any volume of your meeting minutes!'
      />
      <Header fullHeader={false} showNavbarLinks={false} />
      <RootContainer>
        <TextWrapper>
          <h2>Municipal Transcription - Summarized Meeting Minutes</h2>
          <p>Do you need summarized meeting minutes?</p>
          <p>
            We know that producing meeting minutes for local government
            organizations is a tedious and time-consuming process.
          </p>
          <p>We can help you with that!</p>
          <p>
            We worked hard on scaling up our systems and are able to accommodate
            any client, no matter the volume of their meeting minutes.
          </p>
          <p>
            We serve cities and other government organizations and we process
            1000+ meeting hours each month!
          </p>
          <p>
            Contact us at{' '}
            <a href='mailto:support@expresstranscriber.com'>
              support@expresstranscriber.com
            </a>
          </p>
        </TextWrapper>
        <Clients />
      </RootContainer>
    </Layout>
  )
}

export default MunicipalTranscription
